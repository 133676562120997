<template>
  <div class="subscription-product-listing" t-id="b2b-subscription-product-listing">
    <telia-grid class="subscription-product-listing__grid-container">
      <div
        v-for="(products, index) in subscriptionGroups"
        :t-id="`b2b-subscription-product-listing__product-group-${products.productCode}`"
        :key="`${products.name}-${index}-header`"
      >
        <telia-row spacing="md" gap class="subscription-product-listing__product-group__row">
          <telia-col width="12">
            <telia-heading tag="h3" variant="title-300">{{ products.name }}</telia-heading>
            <telia-divider />
            <telia-p>{{ products.description }}</telia-p>
          </telia-col>
        </telia-row>
        <telia-row
          :key="`${products.name}-${index}-products`"
          spacing="md"
          gap
          class="subscription-product-listing__product-group__row"
        >
          <subscription-product
            v-for="product in products.products"
            :key="product.id"
            :product="product"
            :disabled="subscriptionDisabled"
            @selected-subscription="handleSelectedSubscription(products, $event)"
          />
        </telia-row>
      </div>
    </telia-grid>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateSetup, translateMixin } from "./locale";
import SubscriptionProduct from "./components/subscription-product.ce.vue";

export default defineComponent({
  name: "b2b-subscription-product-listing",
  mixins: [translateMixin],
  components: {
    SubscriptionProduct,
  },
  props: {
    tscid: {
      type: String,
      required: true,
    },
    subscriptionGroups: {
      type: Array,
      deafult: () => [],
    },
    subscriptionDisabled: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    await translateSetup();
  },
  methods: {
    handleSelectedSubscription(productGroup, productCode) {
      this.$emit("selected-subscription", {
        offerCode: productGroup.productCode,
        productCode: productCode,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

.subscription-product-listing {
  margin-bottom: $telia-spacing-64;
  &__grid-container {
    padding: $telia-spacing-0;
  }

  &__product-group__row {
    margin-top: $telia-spacing-2;
  }
}
</style>
