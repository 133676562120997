<template>
  <telia-col width="12" width-md="6" width-lg="3">
    <div
      :t-id="`subscription-product__product-${product.productCode}`"
      class="subscription-product__card"
      role="button"
      :class="{ 'subscription-product__card--disabled': disabled }"
      @click="handleSelected"
    >
      <div
        class="subscription-product__amount-wrapper"
        :class="{ 'subscription-product__amount-wrapper--disabled': disabled }"
      >
        <telia-icon
          v-if="isVariable"
          class="subscription-product__variable-icon"
          t-id="subscription-product__filter-icon"
          name="filter"
          size="lg"
        />
        <span v-else class="subscription-product__data-name" t-id="subscription-product__name">
          {{ product.name }}
        </span>
        <div
          v-if="isVariable"
          class="subscription-product__amount-suffix"
          t-id="subscription-product__amount-suffix"
        >
          Bas
        </div>
      </div>
      <telia-divider />
      <div
        class="subscription-product__price"
        :class="{ 'subscription-product__price--disabled': disabled }"
      >
        <telia-p class="subscription-product__price-text" t-id="subscription-product__price-text">
          {{ price }}
        </telia-p>
      </div>
    </div>
  </telia-col>
</template>

<script>
import { currentLanguage } from "@telia/b2b-i18n";
import { defineComponent } from "vue";
import { translateSetup, translateMixin } from "../locale";

export default defineComponent({
  name: "subscription-product",
  mixins: [translateMixin],
  props: {
    product: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },
  async created() {
    await translateSetup();
  },
  computed: {
    isVariable() {
      return this.product.name.toUpperCase().includes("BAS");
    },
    price() {
      const price = this.formatCurrency(this.product.recurringFee || this.product.price);
      return `${price}${this.recurringPriceSuffix}`;
    },
    recurringPriceSuffix() {
      return this.product.recurringFee ? this.t("subscriptionPrice.suffix") : "";
    },
  },
  methods: {
    formatCurrency(amount) {
      return new Intl.NumberFormat(currentLanguage(), {
        style: "currency",
        currency: "SEK",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(amount);
    },
    handleSelected() {
      this.$emit("selected-subscription", this.product.productCode);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/borders/tokens";
.subscription-product {
  &__card {
    display: block;
    box-sizing: border-box;
    box-shadow: 0 0 0 0.1rem $telia-gray-300;
    background-color: $telia-white;
    cursor: pointer;
    outline: none;

    &:not(&--disabled) {
      &:focus,
      &:hover {
        box-shadow: 0 0 0 0.2rem $telia-purple-500;
      }
    }

    &--disabled {
      pointer-events: none;
      cursor: not-allowed;
      border-width: $telia-border-width-2;
      background-color: $telia-gray-50;
    }
  }

  &__amount-wrapper {
    padding: $telia-spacing-20 $telia-spacing-24 $telia-spacing-12;
    display: flex;
    align-items: baseline;
    color: $telia-purple-600;

    &--disabled {
      color: $telia-gray-200;
    }
  }

  &__data-name {
    font-size: 2rem;
    font-weight: bold;
    min-height: 5rem;
  }
  &__amount-suffix {
    font-size: 2rem;
    font-weight: bold;
    margin-left: $telia-spacing-8;
  }

  &__infinite-icon {
    width: 5.6rem;
    height: 100%;
  }

  &__variable-icon {
    width: 5.2rem;
    min-height: 3.7rem;
    margin-top: -0.4rem;
  }
  &__price {
    background-color: $telia-gray-50;
    padding: $telia-spacing-12 $telia-spacing-24;

    &--disabled {
      background-color: $telia-gray-100;
      color: $telia-gray-400;
    }
  }
  &__price-text {
    margin-bottom: 0;
  }
}
</style>
